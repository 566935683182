<template>
  <div>
    <div class="img" />
    <div class="main">
      <div class="list" v-loading="loading">
        <div class="tab" v-for="item in tableData" :key="item.uuid">
          <span class="span1">{{item.title}}</span>
          <span class="span2">{{item.createTime}}</span>
          <span class="span3" v-html="item.content"></span>
          <button @click="details(item.uuid)">查看详情</button>
        </div>
      </div>
      <div class="background">
        <el-pagination
          background
          layout="prev, pager, next"
          @prevClick="pager"
          @nextClick="pager"
          @current-change="pager"
          prev-text="上一页"
          next-text="下一页"
          :page-size="6"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有  <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a> </span>
    </div>
  </div>
</template>

<script>
import {article} from '@/api/home.js'
export default {
  data () {
    return {
      loading: false,
      tableData: [],
      total: 0
    }
  },
  created () {
    this.refresh()
  },
  methods: {
    refresh () {
      article({page: 1, size: 6, sort: 'id,desc', sectionCode: '1', deleteFlag: '0'}).then(res => {
        console.log(res)
        this.tableData = res.content
        this.total = res.totalElements
      })
    },
    pager (e) {
      console.log(e)
      this.loading = true
      article({page: e, size: 6, sort: 'id,desc', sectionCode: '1', deleteFlag: '0'}).then(res => {
        this.loading = false
        console.log(res)
        this.tableData = res.content
        this.total = res.totalElements
      })
    },
    details (uuid) {
      console.log(uuid)
      this.$router.push({path: '/news/details', query: {id: uuid}})
    }
  }
}
</script>

<style lang="less" scoped>
.img {
  height: 180px;
  background: url('../../assets/img/img新闻中心.png') no-repeat center;
}
.main {
  padding: 40px 0 40px;
  background-color: #F4F5F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .list {
    display: flex;

    flex-wrap: wrap;
    width: 1200px;
    min-height: 636px;
    .tab {
      width: 380px;
      height: 288px;
      background: #FFFFFF;
      box-shadow: 0px 8px 18px 2px rgba(136, 161, 188, 0.18);
      border-radius: 8px;
      padding: 40px 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 15px 10px;
      transition: all .5s;
      .span1 {
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .span2 {
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .span3 {
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 28px;

        display: block;
        width: 100%;
        height: 56px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;


        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        /deep/ img {
          display: none;
        }
        /deep/ p, /deep/ h2, /deep/ strong {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
      button {
        width: 96px;
        height: 40px;
        border: 1px solid #DDDDDD;
        border-radius: 20px;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        background-color: #FFFFFF;
        cursor: pointer;
        transition: all .5s;
      }
      button:hover {
        border: 1px solid #2E4E9A;
        color: #2E4E9A;
        transition: all .5s;
      }
    }
    .tab:hover {
      box-shadow: 0px 6px 30px 0px rgba(115, 139, 165, 0.4);
      transition: all .5s;
    }

  }
}
.background {
  margin-top: 30px;
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2E4E9A;
  }
  /deep/ .el-pager li {
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    border-radius: 5px;
  }
  /deep/ .el-pagination button {
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 0 12px;
  }
}

.foot {
  height: 64px;
  background: #393D41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #B1A9A9;
}
</style>
